<template>
  <master-layout>

    <ion-fab
      vertical="top"
      horizontal="start"
      slot="fixed"
      class="closebutton"
      @click="closeRating()"
    >
      <ion-fab-button color="light">
        <ion-icon name="close"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <div class="container">
      <div class="centered-content">
        <div class="illustration-container">
          <img src="assets/images/illustration-rating.svg" class="Illustration"/>
        </div>
        <h1>{{ thanksMessage }}</h1>
        <p class="thanks-message">Véleményed hamarosan megjelenik a borászatnál!</p>
      </div>
    </div>

  </master-layout>
</template>

<script>
import {
  IonFab, IonFabButton, IonIcon
} from '@ionic/vue'
import { addIcons } from 'ionicons'
import {
  close
} from 'ionicons/icons'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'RatingThanks',
  components: {
    IonFab, IonFabButton, IonIcon
  },
  computed: {
    ...mapGetters('wineries', {
      ratingInMemory: 'getRatingInMemory'
    }),
    thanksMessage () {
      if(this.ratingInMemory && this.ratingInMemory.Comment){
        return 'Köszönjük az értékelésed és véleményed!'
      } else {
        return 'Köszönjük az értékelésed!'
      }
    }
  },
  created () {
    addIcons({
      'close': close
    })
  },
  methods: {
    ...mapActions('wineries', {
      setRatingInMemory: 'setRatingInMemory'
    }),
    closeRating () {
      this.$router.push('/boraszatok')
    }
  }
}
</script>

<style scoped>
.closebutton{
  margin-top: calc(10px + env(safe-area-inset-top));
}
.container{
  height: 100%;
  display: flex;
  align-items: center;
}
.centered-content{
  width: 100%;
}
.illustration-container{
  width: 50%;
  margin: 40px auto 40px auto;
}
h1{
  font-size: 20px;
  margin-top: 0px;
}
p{
  margin: 25px 20px 0 20px;
  line-height: 1.5;
  text-align: center;
}
</style>
